import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Logo from "../../../assets/images/boeing_blue_logo.png";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  mastHeader: {
    margin: 0,
    padding: 0,
    border: 0,
    backgroundColor: "#fff",
    display: "flex",
    boxShadow: theme.shadows[2]
  },
  title: {
    flexGrow: 8,
    textAlign: "right",
    color: theme.palette.primary.main,
    padding: 20,
    fontWeight: 500,
    fontSize: "1.25rem",
    flex: 1
  },
  image: {
    height: 35,
  },
}));


export const Header = () => {
  const classes = useStyles();
  return (
    <AppBar position="static" className={classes.mastHeader}>
      <Toolbar component="div">
        <img src={Logo} className={classes.image} alt="logo"/>
        <div className={classes.title}>Boeing Unsolicited Ideas Form</div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
