import React from 'react';
import {Typography} from '@material-ui/core';

export const Footer = () => <footer id="footer">
  <Typography variant="caption">
    Copyright &copy; - <a target="_blank" href="https://my.boeing.com/"
                          rel="noopener noreferrer"><strong>Boeing </strong></a> 2021. All rights reserved.
  </Typography>
  <br/>
  <Typography variant="caption" data-testid="privacy-statement">Boeing <a target="_blank" href="https://www.boeing.com/privacy-and-cookie-policy.page"
                                          rel="noopener noreferrer">Privacy Statement</a>.</Typography>
  <Typography variant="caption">The site is best viewed through Internet Explorer 11.x or higher / Mozilla Firefox® 60.x
    or higher.</Typography>
</footer>;
