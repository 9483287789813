import React from 'react';
import {makeStyles, Paper} from '@material-ui/core/index';
import {Done} from '@material-ui/icons';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '50px auto',
    width: '100%'
  },
  successMessage: {
    padding: 50,
  },
  successSymbol: {
    backgroundColor: '#4caf50',
    color: '#fff',
    display: 'flex',
    justifyContent: 'flext-start',

    alignItems: 'center',
    height: 50,
    padding: 10,
    textAlign: 'center',
  },
  successTitle: {
    marginLeft: 10
  },
  icon: {
    color: '#fff'
  }
}));
export const Success = () => {
  const classes = useStyles();
  return <Paper className={classes.root}>
    <Paper className={classes.successSymbol}>
      <Done className={classes.icon}/> <span className={classes.successTitle}>Success</span>
    </Paper>
    <div className={classes.successMessage}>
      Thank you!! Your idea has been successfully submitted for review.
    </div>
  </Paper>;
};

