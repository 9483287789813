import {createTheme} from '@material-ui/core/styles';

export const theme = createTheme({
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: '#db3131',
        '&$error': {
          color: '#db3131'
        },
      }
    }
  },
  palette: {
    primary: {
      light: '#335bb3',
      main: '#0033a1',
      dark: '#002370',
      contrastText: '#ffffff',
    },
    secondary: {
      light: '#33afe5',
      main: '#009BDF',
      dark: '#006c9c',
      contrastText: '#ffffff',
    },
    text: {
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
      primary: "rgba(0, 0, 0, 0.9)",
      secondary: "rgba(0, 0, 0,.75)",
    }
  },
  typography: {
    h1: {
      fontFamily: ['"HelveticaNeueW01-55Roma"', 'Arial', 'sans-serif'].join(','),
      fontSize: '32px',
      lineHeight: '34px'
    },
    h2: {
      fontFamily: ['"HelveticaNeueW01-55Roma"', 'Arial', 'sans-serif'].join(','),
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 600
    },
    h3: {
      fontFamily: ['"HelveticaNeueW01-55Roma"', 'Arial', 'sans-serif'].join(','),
      fontSize: '18px',
      lineHeight: '24px'
    },
    h4: {
      fontFamily: ['"HelveticaNeueW01-55Roma"', 'Arial', 'sans-serif'].join(','),
      fontSize: '15px',
      lineHeight: '20px'
    },
    h5: {
      fontFamily: ['"HelveticaNeueW01-55Roma"', 'Arial', 'sans-serif'].join(','),
      fontSize: '13px',
      lineHeight: '16px'
    },
    h6: {
      fontFamily: ['"HelveticaNeueW01-55Roma"', 'Arial', 'sans-serif'].join(','),
      fontSize: '12px',
      lineHeight: '15px'
    },
    body1: {
      fontFamily: ['"HelveticaNeueW01-55Roma"', 'Arial', 'sans-serif'].join(','),
      fontSize: '16px',
    },
    body2: {
      fontFamily: ['"HelveticaNeueW01-55Roma"', 'Arial', 'sans-serif'].join(','),
      fontSize: '16px',
      fontWeight: 600
    },
    button: {
      fontFamily: ['"HelveticaNeueW01-55Roma"', 'Arial', 'sans-serif'].join(','),
    },
    caption: {
      fontFamily: ['"HelveticaNeueW01-55Roma"', 'Arial', 'sans-serif'].join(','),
    },
    subtitle1: {
      fontFamily: ['"HelveticaNeueW01-55Roma"', 'Arial', 'sans-serif'].join(','),
    },
    subtitle2: {
      fontFamily: ['"HelveticaNeueW01-55Roma"', 'Arial', 'sans-serif'].join(','),
    },
    overline: {
      fontFamily: ['"HelveticaNeueW01-55Roma"', 'Arial', 'sans-serif'].join(','),
    },
  }
});
