import React from 'react';
import {Autocomplete} from '@material-ui/lab';
import {TextField} from '@material-ui/core';

interface Props {
  options: Array<CountryType>,
  name: string;
  setFieldValue: any;
  label: string;
  callback?: any;
  disabled?:boolean;
}

interface CountryType {
  id: string;
  name: string;
  phone: string;
}

export default function Dropdown(props: Props) {
  const {options, disabled, name, setFieldValue, label, callback} = props;
  const [value, setValue] = React.useState<CountryType | null>(null);
  return <Autocomplete
    id={name}
    options={options}
    getOptionLabel={(option: CountryType) => option.name}
    getOptionSelected={option => option.id === value?.id}
    value={value}
    onBlur={(e) => {
      setFieldValue(name, value?.name);
    }}
    disabled={disabled}
    onChange={(event: any, newValue: CountryType | null) => {
      setValue(newValue);
      if (callback) {
        callback(newValue?.id);
      }
    }}
    renderInput={(params) => <TextField data-testid={name} {...params} required name={name} variant="outlined" size="small" label={label}/>}
  />
}
