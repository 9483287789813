import React, {useEffect, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import {
  Box,
  Checkbox, CircularProgress,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton, LinearProgress,
  makeStyles,
  Slide,
  Snackbar,
  Typography
} from '@material-ui/core';
import './form.css';
import {useFormik} from 'formik';
import * as yup from 'yup';
import Button from '@material-ui/core/Button';
import Alert from '@material-ui/lab/Alert';
import TextField from '../Components/TextField'
import {Refresh} from '@material-ui/icons';
import {Editor} from '../Components/Editor';
import lookupService from '../services/lookupService';
import Dropdown from '../Components/Dropdown';
import captchaService from '../services/captchaService';
import unsolicitedIdeasService from '../services/unsolicitedIdeasService';
import {Success} from '../Success';

const useStyles = makeStyles(theme => ({
  submit: {
    textAlign: 'center',
    marginTop: 10
  }
}));


const validationSchema = yup.object({
  emailAddress: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  phoneNumber: yup.number().typeError('Phone Number should be a number').required('Phone Number is required'),
  country: yup.string().required('Country is required'),
  state: yup.string().required('State is required'),
  acceptTerms: yup.boolean().oneOf([true], 'This field is mandatory'),
  consent: yup.boolean().oneOf([true], 'This field is mandatory'),
  captcha: yup.string().required("Please enter the characters"),
});

function SlideTransition(props: any) {
  return <Slide {...props} direction="up"/>;
}

function mapValuesToPayload(values: any) {
  return {
    firstName: values.firstName,
    lastName: values.lastName,
    phone: values.phoneNumber,
    email: values.emailAddress,
    country: values.country,
    state: values.state,
    description: values.ideaDescription,
    captcha: values.captcha
  };
}

const initialValues = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  emailAddress: '',
  country: '',
  state: '',
  ideaDescription: '',
  acceptTerms: false,
  consent: false,
  captcha: ''
};

export const Form = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [isLoading, setLoading] = useState(false);
  const [charCount, setCharCount] = useState(0);
  const [countries, setCountries] = useState([]);
  const [captchaUri, setCaptchaUri] = useState('');
  const [states, setSates] = useState([]);
  const [statesLoading, setStatesLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Promise.all([lookupService.getCountries(), captchaService.getCaptcha()])
      .then((data: any) => {
        setLoading(false);
        setCountries(data[0].data);
        const svgString = encodeURIComponent(data[1].data);
        const dataUri = `url("data:image/svg+xml,${svgString}")`;
        setCaptchaUri(dataUri);
      });
  }, []);

  function reFreshCaptcha() {
    captchaService.getCaptcha().then((data: any) => {
      const svgString = encodeURIComponent(data.data);
      const dataUri = `url("data:image/svg+xml,${svgString}")`;
      setCaptchaUri(dataUri);
    });
  }

  function loadStates(countryId: number) {
    if (countryId) {
      setStatesLoading(true);
      lookupService.getStates(countryId).then((data: any) => {
        setSates(data.data);
        setStatesLoading(false);
      });
    }
  }

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event: any, reason?: any) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const formik = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: true,
    validationSchema: validationSchema,
    onSubmit: (values: any, {setErrors, setStatus, setSubmitting}) => {

      if (charCount === 0) {
        setErrors({ideaDescription: 'Please enter your idea.'});
        setSubmitting(false);
      } else if (charCount > 5000) {
        setErrors({ideaDescription: 'Maximum allowable characters reached.'});
        setSubmitting(false);
      } else {
        unsolicitedIdeasService.submitIdea(mapValuesToPayload(values)).then(data => {
          setSubmitting(false);
          setStatus({success: true});
          handleClick();
        }).catch(error => {
          if (error.status === 400) {
            setErrors({captcha: 'Invalid captcha'});
          } else {
            setErrors({submit: 'Something went wrong, please try again later.'});
          }
          setSubmitting(false);
          setStatus({success: false});
        });
      }
    },
  });

  return formik.status && formik.status.success ? <Success/> : <div className="form">
    {isLoading && <LinearProgress data-testid="loading" variant="indeterminate"/>}
    {!isLoading && <Box mt={8}>
      <Typography variant="h2" color="primary">
        Unsolicited Ideas – Web Submission Form
      </Typography>
      <Box my={4} width={'100%'}>
        <Box mb={1}><Typography variant="subtitle1">Sender’s Information:</Typography></Box>
        <Typography component="div" variant="body1">
          <form onSubmit={formik.handleSubmit} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  name="firstName"
                  inputProps={{'data-testid': 'firstName', maxLength: 40}}
                  label="First Name"
                  formik={formik}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="lastName"
                  label="Last Name"
                  inputProps={{'data-testid': 'lastName', maxLength: 40}}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="phoneNumber"
                  label="Phone Number"
                  isNumber={true}
                  inputProps={{'data-testid': 'phoneNumber', maxLength: 20}}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="emailAddress"
                  label="Email Address"
                  inputProps={{'data-testid': 'email', maxLength: 50, type: 'email'}}
                  formik={formik}
                />
              </Grid>
              <Grid item xs={6}>
                <Dropdown options={countries} name="country" setFieldValue={formik.setFieldValue} label="Country"
                          callback={(id: number) => {
                            loadStates(id);
                            formik.setFieldValue('state', '');
                          }}/>
                <FormHelperText
                  error={formik.touched.country && Boolean(formik.errors.country)}>{formik.touched.country && formik.errors.country}</FormHelperText>
              </Grid>
              {states.length > 0 && <Grid item xs={6}>

                <Dropdown options={states.sort((a: any, b: any)=> a.name.localeCompare(b.name))} name="state" disabled={statesLoading} setFieldValue={formik.setFieldValue}
                          label="State"/>
                <FormHelperText
                  error={formik.touched.state && Boolean(formik.errors.state)}>{formik.touched.state && formik.errors.state}</FormHelperText>
              </Grid>}
              {states.length === 0 && <Grid item xs={6}> <TextField
                name="state"
                label="State"
                inputProps={{'data-testid': 'state-input'}}
                formik={formik}
              /></Grid>}

              <Grid item xs={12}>
                <Editor initialValue={formik.values.ideaDescription} charCount={charCount} setCharCount={setCharCount}
                        formik={formik}/>

              </Grid>
              <Grid item xs={12}>

                <FormControlLabel
                  control={<Checkbox checked={formik.values.acceptTerms} onChange={formik.handleChange}
                                     name="acceptTerms"
                                     color="primary"/>}
                  label="I understand that in submitting my idea I am agreeing to Boeing’s Terms & Conditions."
                />
                {formik.touched.acceptTerms && Boolean(formik.errors.acceptTerms) &&
                <FormHelperText error={true}>{formik.touched.acceptTerms && formik.errors.acceptTerms}</FormHelperText>}
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  style={{display: 'table'}}
                  control={<div style={{display: 'table-cell'}}>
                    <Checkbox checked={formik.values.consent}
                              onChange={formik.handleChange} name="consent"
                              color="primary"/></div>}
                  label={<div data-testid="agreement2" className="disclaimerandnotes">I consent to Boeing’s use of my personal information to
                    process this submission and for related purposes such as communicating with me. Please see our
                    <a href={' https://www.boeing.com/privacy-and-cookie-policy.page'} target="_blank" rel="noopener noreferrer"> Privacy Statement</a> for details on how Boeing processes and protects your data.</div>}
                />
                {formik.touched.consent && Boolean(formik.errors.consent) &&
                <FormHelperText error={true}>{formik.touched.consent && formik.errors.consent}</FormHelperText>}
              </Grid>
            </Grid>
            <Box mt={3}/>
            <Grid container justify="center" alignItems="center">
              <Grid item xs={6}>
                <Grid container justify="center" alignItems="center">
                  <Grid item xs={8}>
                    <div
                      data-testid="captcha"
                      style={{
                        backgroundImage: captchaUri,
                        width: 300,
                        height: 60,
                      }}/>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton data-testid="refresh-captcha" color="primary" aria-label="refresh captcha"
                                onClick={reFreshCaptcha}>
                      <Refresh/>
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
            <Grid container justify="center">
              <Grid item xs={12} md={5}>
                <Box mt={1}>
                  <TextField
                    name="captcha"
                    inputProps={{'data-testid': 'captcha-input'}}
                    label="Type the characters you see in the image"
                    formik={formik}
                  /></Box>
              </Grid>
              {formik.errors.submit && (
                <Grid item xs={12}>
                  <FormHelperText error className={classes.submit}>
                    {formik.errors.submit}
                  </FormHelperText>
                </Grid>
              )}
              <Grid item xs={12} className={classes.submit}>
                <Button color="primary" variant="contained" type="submit" disabled={formik.isSubmitting}
                        data-testid="submit"
                        startIcon={formik.isSubmitting && <CircularProgress size={20} variant="indeterminate"/>}>
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Typography>
      </Box>
    </Box>}
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} TransitionComponent={SlideTransition}>
      <Alert onClose={handleClose} severity="success">
        Thank you for sharing your idea.
      </Alert>
    </Snackbar>
  </div>
};
