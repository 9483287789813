import React, {memo, useState} from 'react';
import TextField from '@material-ui/core/TextField/TextField';

interface Props {
  name: string;
  inputProps?: any;
  label: string;
  isNumber?: boolean;
  formik: any;
}

export default memo(function Input(props: Props) {
  const formik = props.formik;
  const [value, setValue] = useState(props.formik.values[props.name]);

  return <TextField
    fullWidth={true}
    size="small"
    variant="outlined"
    name={props.name}
    required={true}
    inputProps={props.inputProps}
    label={props.label}
    value={value}
    onBlur={formik.handleChange}
    error={formik.touched[props.name] && Boolean(formik.errors[props.name])}
    helperText={formik.touched[props.name] && formik.errors[props.name]}
    onChange={e => {
      if (props.isNumber) {
        const onlyNums = e.target.value.replace(/[^0-9]/g, '');
        setValue(onlyNums);
      } else {
        setValue(e.target.value);
      }
    }}
  />;
})
