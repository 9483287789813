import axios from 'axios';

class UnsolicitedIdeasService {
  submitIdea = (data: any) => new Promise(((resolve, reject) => {
    axios.post('/api/submit', data)
      .then(data => resolve(data))
      .catch(error => reject(error.response));
  }))
}
const unsolicitedIdeasService = new UnsolicitedIdeasService();
export default unsolicitedIdeasService;
