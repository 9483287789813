import React from 'react';
import {Typography} from '@material-ui/core';
import TermsAndConditionsDialog from './TermsAndConditions';
import GuideLinesDialog from './GuideLines/indes';

export const Instructions = () => {
  return <Typography component="p" variant="body1" className="instructions" data-testid="t&c">
    Please review Boeing’s <TermsAndConditionsDialog/> and <GuideLinesDialog/> before
    submitting your idea to
    Boeing. Please note that in return for your consent to Boeing’s terms and conditions, which include a
    transfer of ownership and an acknowledgement that Boeing is not obligated to keep your submission or its
    content confidential, Boeing will review your idea.
  </Typography>
};
