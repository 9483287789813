import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {Box, Link, Typography} from '@material-ui/core';

export default function TermsAndConditionsDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
      <Link href="#" onClick={handleClickOpen}>
        Terms & Conditions
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Box p={3}><Typography variant="h2" id="alert-dialog-title" style={{textAlign: 'center'}}
                               data-testid="title">TERMS AND CONDITIONS</Typography></Box>
        <DialogContent>
          <Typography id="alert-dialog-description" data-testid="intro" variant="body2">
            In return for the review of your submission by The Boeing Company (“Boeing”), you agree to the below terms.
            Boeing rejects any additional or conflicting terms in your submission, and they will not form part of your
            agreement with Boeing. If you do not agree to the below terms, do not submit your ideas to this website.
            </Typography>
          <Typography variant="body1" component="div">
            <ol>
              <li data-testid='term1'>Your submission is neither confidential nor proprietary, and will not be treated by Boeing as
                confidential or proprietary.
              </li>
              <li data-testid='term2'>Your submission, including all ideas, unpatented inventions, drawings, software source code, technical
                data, etc., and all associated intellectual property rights, will automatically become the property of
                Boeing and you hereby assign to Boeing all rights in your submission, including all intellectual
                property rights, without any expectation of compensation to you.
              </li>
              <li data-testid='term3'>You are at least 18 years old at the time of your submission.</li>
              <li data-testid='term4'>You are authorized to make this submission, and your submission does not include any information that
                belongs to a third party.
              </li>
              <li data-testid='term5'>These terms and conditions apply regardless of any contrary assertions you may include with your
                submission or otherwise communicate to Boeing.
              </li>
              <li data-testid='term6'>Please note that Boeing is under no obligation to respond to your submission.</li>
            </ol>
          </Typography>
        </DialogContent>
        <DialogActions style={{justifyContent: 'center'}}>
          <Button onClick={handleClose} data-testid='ok-button' color="primary" autoFocus variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
