import React, {useState} from 'react';
import {FormHelperText, Typography} from '@material-ui/core';
import ReactQuill from 'react-quill';

const formats = [
  'header', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link'
];

const modules = {
  toolbar: [
    [{'header': '1'}, {'header': '2'}],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'},
      {'indent': '-1'}, {'indent': '+1'}],
    ['link'],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
};

interface Props {
  initialValue: any;
  formik: any;
  charCount: any;
  setCharCount: any;
}

export function Editor(props: Props) {
  const [value, setValue] = useState('');
  return <>
    <Typography variant="subtitle1">Describe your idea</Typography>
    <ReactQuill theme="snow" value={value}
                formats={formats}
                modules={modules}
                data-testid="editor"
                placeholder={'Please enter your idea here.'}
                onBlur={e => props.formik.setFieldValue('ideaDescription', value)}
                onChange={(value, ig, nore, e: any) => {
                  setValue(value);
                  props.setCharCount(e.getText().trim().length)
                }}/>
    <FormHelperText error={props.charCount > 5000}>{props.charCount}/5000</FormHelperText>

    <FormHelperText
      error={props.formik.touched.ideaDescription && Boolean(props.formik.errors.ideaDescription)}>{props.formik.touched.ideaDescription && props.formik.errors.ideaDescription}</FormHelperText></>
}
