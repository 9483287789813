import axios from 'axios';

class LookupService {
  getCountries() {
    return new Promise((resolve, reject) => {
      axios.get('/api/countries')
        .then(data => resolve(data))
        .catch(error => reject(error));
    });
  }
  getStates(countryId: number){
    return new Promise((resolve, reject) => {
      axios.get(`/api/states/${countryId}`)
        .then(data => resolve(data))
        .catch(error => reject(error));
    })
  }

}

const lookupService = new LookupService();
export default lookupService;
