import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import {Box, Link, Typography} from '@material-ui/core';

export default function GuideLinesDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
      <Link href="#" onClick={handleClickOpen}>
        Guidelines for Submission
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
         <Box p={3}><Typography variant="h2" id="alert-dialog-title" style={{textAlign: 'center'}}
                                data-testid="title">GUIDELINES FOR SUBMISSION</Typography></Box>
        <DialogContent>
          <Typography id="alert-dialog-description" data-testid="intro" variant="subtitle1">Submissions should be in the English language.</Typography>

          <Typography variant="body2">Ideas that contain or relate to the following are not eligible for review by Boeing:</Typography>
          <Typography variant="body1" component="div">
            <ol>
              <li data-testid="term1">Confidential or proprietary information of any type</li>
              <li data-testid="term2">Patents, licensing requests, or patent assertions</li>
              <li data-testid="term3">Submissions from anyone under 18 years of age</li>
              <li data-testid="term4">Advertising, marketing, customer liveries or branding ideas</li>
              <li data-testid="term5">Names, logos, and slogans for products and services</li>
              <li data-testid="term6">Business methods or ways to conduct our business (e.g., joint venture proposals, diverstiture, equity investment, funding of projects)</li>
              <li data-testid="term7">Requests for employment (instead please see <a target="_blank" rel="noopener noreferrer" href={'https://jobs.boeing.com/'}>https://jobs.boeing.com/</a>)</li>
              <li data-testid="term8">Website development proposals</li>
              <li data-testid="term9">Any solicitations for sales or license</li>
              <li data-testid="term10">Sponsorship proposals</li>
              <li data-testid="term11">Any submissions on behalf of a third party</li>
              <li data-testid="term12">Requests to be a supplier to The Boeing Company (instead please see <a target="_blank" rel="noopener noreferrer" href={'http://www.boeingsuppliers.com/#/become'}>http://www.boeingsuppliers.com/#/become</a>)</li>
            </ol>
          </Typography>
        </DialogContent>
        <DialogActions style={{justifyContent: 'center'}}>
          <Button onClick={handleClose} data-testid='ok-button' color="primary" autoFocus variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
