import React, {useState} from 'react';
import './App.css';
import Header from './layout/Header';
import {Footer} from './layout/Footer';
import {Box, Button, Container} from '@material-ui/core';
import {Instructions} from './Instructions';
import {Form} from './Form';

function App() {
  const [accepted, setAccepted] = useState(false);
  return (
    <div className="App">
      <Header/>
      <Container maxWidth="md" className="content">
        {!accepted && <div>
          <Box mt={20}>
            <Instructions/>
          </Box>
          <Box mx="auto" mt={5} className="continue-button">
            <Button variant="contained" color="primary" onClick={_ => setAccepted(true)}>
              To submit your idea, click here
            </Button>
          </Box>
        </div>}
        {accepted && <Form/>}
      </Container>
      <Footer/>
    </div>
  );
}

export default App;
